import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Spin, Steps } from 'antd';
import dayjs from 'dayjs';
import { toPng } from 'html-to-image';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  axiosInstance,
  customerApi,
  employeeApi,
  fetchApiPrint,
  salonApi,
  servicesApi,
  settingApi,
} from '../../../../apis';
import {
  CheckInAppointment,
  CheckInBulkDTO,
  Customer,
  Employee,
  ServiceItems,
  SettingPrinter,
  SettingPrinterCloverPrinterEnum,
} from '../../../../apis/client-axios';
import { SvgArrowBackIcon } from '../../../../components/@svg/SvgArrowBackIcon';
import CheckInQRCode from '../../../../components/CheckInQRCode';
import { FormCheckbox } from '../../../../components/Form/FormCheckbox';
import FormWrap from '../../../../components/FormWrap';
import NotificationError from '../../../../components/HandleShowNotiError';
import CustomerCheckin from '../../../../components/Print/CustomerCheckin';
import { StyledPopup } from '../../../../components/StyledPopup';
import { ButtonStyled } from '../../../../components/buttons/ButtonStyled';
import { RootState, useAppDispatch } from '../../../../store';
import {
  setDeviceError,
  setIsDeviceReady,
  setIsDisconnected,
  setPairingCode,
  setPrintResponse,
} from '../../../../store/cloverSlice';
import {
  DATE_FORMAT_FULL_DATE,
  DATE_FORMAT_FULL_DATE_3,
  formatDateTimeUTC,
  getStorageByName,
  timeZoneSalon,
  toDayjsTimeZone,
  toDaysByTimeZoneServer,
} from '../../../../utils';
import {
  QUERY_CUSTOMER_REWARD_BALANCE,
  QUERY_LIST_TECHNICIAN,
  QUERY_SALONS_DETAIL,
  QUERY_SERVICES,
  QUERY_SETTING,
} from '../../../../utils/constant';
import { MAIN_ROUTES } from '../../routes';
import { useClover } from '../../setting/terminalDeviceSetting/Clover/connection/hooks';
import { BOOKING_ONLINE_ROUTES } from '../bookingOnlineConstants';
import ScreenSaver from '../screenSaver';
import './information.scss';
import StepOne from './stepOne';
import StepThree from './stepThree';
import StepTwo from './stepTwo/index';
import jsPDF from 'jspdf';
import { SettingPrinterReceiptForEnum } from '../../setting/printerSetting';

export interface IListAppointment {
  estimate: number;
  useAnyTechnician: boolean;
  technicianId?: number;
  services: IServiceItems[];
  appointmentId?: number;
}

export interface IServiceItems extends ServiceItems {
  idDelete: number;
}

export interface Booking {
  id: number;
  availableTime?: Date | string;
  //booking
  service?: ServiceItems;
  technician?: Employee;
  isFocus: boolean;
  isAnyTechnician: boolean;
  //appointment
  isAppointment: boolean;
  appointmentServices?: ServiceItems[];
}

const Information = () => {
  const intl = useIntl();
  const [formTerms] = Form.useForm();
  const [step, setStep] = useState<number>(3);
  const [stepCheckIn, setStepCheckIn] = useState<any[]>([
    {
      title: (
        <span className="text-transform-first-letter-capitalize">
          {intl.formatMessage({ id: 'bookingOnline.step1' })}
        </span>
      ),
      icon: <div className="salon__information-step-item">{intl.formatMessage({ id: 'bookingOnline.one' })}</div>,
    },
  ]);
  const [listSelectedService, setListSelectedService] = useState<number[]>([]);
  const [selectedTechnician, setSelectedTechnician] = useState<number>();
  const [appointmentId, setAppointmentId] = useState<number | undefined>();
  const [checkedTechnician, setCheckedTechnician] = useState<boolean>(true);
  const [dataCustomer, setDataCustomer] = useState<Customer>();
  const [receivePromotionViaEmail, setReceivePromotionViaEmail] = useState<boolean>(true);
  const [receiveMessagesByPhone, setReceiveMessagesByPhone] = useState<boolean>(true);
  const [dataDetail, setDataDetail] = useState<Customer>();
  const [status, setStatus] = useState<boolean>(false);
  const [isTerms, setIsTerms] = useState<boolean>(false);
  const [statusSkip, setStatusSkip] = useState<boolean>(false);
  const [listMerchandise, setListMerchandise] = useState<number[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCheckin, setStatusShowCheckin] = useState<boolean>(true);
  const [dataPhoneCheckin, setDataPhoneCheckin] = useState<string>('');
  const [settingPrinter, setSettingPrinter] = useState<SettingPrinter>();

  const [bookings, setBookings] = useState<Booking[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const { cloverDevice, onConnect } = useClover();

  const dispatch = useAppDispatch();
  const printResponse = useSelector((state: RootState) => state.clover.printResponse);
  const isDisconnected = useSelector((state: RootState) => state.clover.isDisconnected);
  const isDeviceReady = useSelector((state: RootState) => state.clover.isDeviceReady);
  const isPairingCodeSuccess = useSelector((state: RootState) => state.clover.isPairingCodeSuccess);
  const pairingCode = useSelector((state: RootState) => state.clover.pairingCode);

  const dataCustomerRef = useRef<Customer>();
  const checkInConfirmInformation = useRef<{ customerId?: number; rewardBalance?: number }>();

  const termsChecked = Form.useWatch('terms', formTerms);

  const salonId = Number(id) || getStorageByName('salonId');

  const { data: dataSalon } = useQuery({
    queryKey: [QUERY_SALONS_DETAIL],
    queryFn: () => salonApi.salonControllerGetCurrentSalon(id),
    enabled: !!id,
    staleTime: 1000,
  });

  const { data: setting, isLoading: settingLoading } = useQuery({
    queryKey: [QUERY_SETTING],
    queryFn: () => settingApi.settingControllerGet(),
    staleTime: 1000,
  });

  const { data: dataTechnicians } = useQuery({
    queryKey: [QUERY_LIST_TECHNICIAN],
    queryFn: () => employeeApi.employeeControllerTechnician(),
    staleTime: 1000,
  });

  const { data: dataServices, isLoading: isLoadingService } = useQuery({
    queryKey: [QUERY_SERVICES],
    queryFn: () => servicesApi.serviceControllerGetBySalon(0),
    staleTime: 1000,
  });

  const onBack = () => {
    if (step && step >= 0) {
      if (!selectedTechnician) {
        setCheckedTechnician(true);
        setReceiveMessagesByPhone(true);
        setReceivePromotionViaEmail(true);
      }
      if (selectedTechnician && listSelectedService) {
        setStatusSkip(true);
      }
      if (
        setting?.data?.settingCheckin?.allowSelectingServices ||
        setting?.data?.settingCheckin?.allowSelectingTechnician
      ) {
        setStep((prev) => prev - 1);
      } else {
        setStep((prev) => prev - 2);
      }
    } else {
      if (setting?.data.settingCheckin?.askForConsent && isTerms) {
        setIsTerms(false);
      } else {
        setDataCustomer(undefined);
        setStatusShowCheckin(true);
      }
    }
  };

  useEffect(() => {
    if (!!setting?.data?.settingPrinter?.length) {
      const printerCompensation = setting?.data.settingPrinter.find((e) =>
        e.receiptFor.includes(SettingPrinterReceiptForEnum.CHECK_OUT)
      );

      setSettingPrinter(printerCompensation);
    }
  }, [setting]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1366) {
      let myInterval = setInterval(() => {
        if (!status) setStatus(true);
      }, 120000);

      const onMouseDown = () => {
        clearInterval(myInterval);
        myInterval = setInterval(() => {
          if (!status) setStatus(true);
        }, 120000);
      };

      document.addEventListener('mousedown', onMouseDown);

      return () => {
        document.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, []);

  useEffect(() => {
    const settingCheckIn = setting?.data?.settingCheckin;

    if (
      settingCheckIn?.autoAssignAppointmentTechnician &&
      dataDetail?.appointments &&
      Array.isArray(dataDetail?.appointments) &&
      Number(dataDetail?.appointments?.length) >= 0
    ) {
      const appoinments = dataDetail.appointments;

      const appointmentBookings: Booking[] = appoinments.map((appointment, index) => ({
        estimate: dayjs(appointment.timeStart).add(appointment.estimate, 'minutes').toDate(),
        isAppointment: true,
        isFocus: index === 0,
        technician: appointment?.technician,
        isAnyTechnician: !!!appointment?.technician,
        appointmentServices: appointment.services,
        availableTime: toDayjsTimeZone(appointment.timeStart).format(DATE_FORMAT_FULL_DATE_3),
        id: appointment.id,
      }));

      setBookings(appointmentBookings);
    }
  }, [dataDetail?.appointments, setting]);

  useEffect(() => {
    if (id) {
      axiosInstance.defaults.headers.salonId = id;
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    if (!isDeviceReady && isLoading && step === 2) {
      timeout = setTimeout(() => {
        setIsLoading(false);
        handleNavigateToBookingConfirm();
        // dispatch(setIsDisconnected(false));
        dispatch(setDeviceError(undefined));
      }, 11000);
    }

    if (pairingCode || isPairingCodeSuccess) {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isDeviceReady, isLoading, pairingCode, isPairingCodeSuccess, dispatch, step]);

  useEffect(() => {
    if (isDeviceReady && isLoading) {
      onPrintTicket();
    }
  }, [isDeviceReady, isLoading]);

  useEffect(() => {
    if (isDisconnected && isLoading && step === 2) {
      handleNavigateToBookingConfirm();
      dispatch(setIsDisconnected(false));
      dispatch(setDeviceError(undefined));
    }
  }, [isDisconnected, dispatch, isLoading, step]);

  useEffect(() => {
    if (printResponse) {
      dataCustomerRef.current = undefined;
      dispatch(setPrintResponse(undefined));

      if (printResponse.getReason() === 'DONE') {
        handleNavigateToBookingConfirm();

        setIsLoading(false);

        if (!printResponse.getSuccess()) {
          NotificationError({ contentNoti: printResponse.getMessage() });
        }
      }
    }
  }, [printResponse, dispatch]);

  const createCustomerCheckinBulkMutation = useMutation(
    (checkIn: CheckInBulkDTO) => customerApi.customerControllerCheckinBulk(checkIn),
    {
      onSuccess: ({ data }) => {
        const settingCheckIn = setting?.data?.settingCheckin;
        const settingTerminalDevice = setting?.data?.settingTerminalDevice;

        checkInConfirmInformation.current = { ...checkInConfirmInformation.current, customerId: data[0]?.customerId };

        if (!settingTerminalDevice || (settingTerminalDevice && !settingTerminalDevice.cloverDeviceAddress)) {
          return handleNavigateToBookingConfirm();
        }

        setIsLoading(true);

        if (settingCheckIn && settingCheckIn.autoPrintCheckinTicketsExternal && settingPrinter) {
          if (settingPrinter?.cloverPrinter === SettingPrinterCloverPrinterEnum.PrinterConnectedViaUsb) {
            onPrintPdf();
            return true;
          }
          onConnect();
        } else {
          handleNavigateToBookingConfirm();
        }
      },
      onError: (error: { response: { data: { message: string }; status: number } }) => {
        NotificationError({ contentNoti: error.response.data.message });
      },
      onSettled: () => {
        setBookings([]);
      },
    }
  );

  const printJobMutation = useMutation((payload: FormData) => fetchApiPrint(payload), {
    onSuccess: () => {
      setIsLoading(false);
    },
    onError: ({ response }) => {
      setIsLoading(false);
      NotificationError({ contentNoti: 'Please connect printer!!' });
    },
  });

  useQuery({
    queryKey: [QUERY_CUSTOMER_REWARD_BALANCE, createCustomerCheckinBulkMutation],
    queryFn: () =>
      customerApi.customerControllerGetRewardBalance(
        createCustomerCheckinBulkMutation.data?.data?.[0]?.customerId?.toString() || ''
      ),
    enabled:
      createCustomerCheckinBulkMutation.isSuccess && !!createCustomerCheckinBulkMutation.data?.data?.[0]?.customerId,
    onSuccess: ({ data }) => {
      checkInConfirmInformation.current = { ...checkInConfirmInformation.current, rewardBalance: data?.rewardBalance };
    },
  });

  const handleNavigateToBookingConfirm = () => {
    navigate('/' + MAIN_ROUTES.BOOKING_ONLINE + BOOKING_ONLINE_ROUTES.CONFIRM + (id ? `/${id}` : ''), {
      state: {
        customerId: checkInConfirmInformation.current?.customerId,
        rewardBalance: checkInConfirmInformation.current?.rewardBalance,
      },
    });
  };

  useEffect(() => {
    const checkSelectServiceOrTechnician =
      setting?.data?.settingCheckin?.allowSelectingServices || setting?.data?.settingCheckin?.allowSelectingTechnician;
    if (!!checkSelectServiceOrTechnician) {
      const tempStep = stepCheckIn;
      tempStep[1] = {
        title: (
          <span className="text-transform-first-letter-capitalize">
            {intl.formatMessage({ id: 'bookingOnline.step2' })}
          </span>
        ),
        icon: <div className="salon__information-step-item">{intl.formatMessage({ id: 'bookingOnline.two' })}</div>,
      };
      setStepCheckIn(tempStep);
    }
    if (setting?.data?.settingCheckin?.asksOptionReceivingTextMessage) {
      const tempStep = stepCheckIn;
      tempStep[!!checkSelectServiceOrTechnician ? 2 : 1] = {
        title: (
          <span className="text-transform-first-letter-capitalize">
            {intl.formatMessage({ id: 'bookingOnline.step3' })}
          </span>
        ),
        icon: (
          <div className="salon__information-step-item">
            {!!checkSelectServiceOrTechnician
              ? intl.formatMessage({ id: 'bookingOnline.three' })
              : intl.formatMessage({ id: 'bookingOnline.two' })}
          </div>
        ),
      };
      setStepCheckIn(tempStep);
    }
  }, [setting]);

  useEffect(() => {
    if (!!salonId) {
      axiosInstance.defaults.headers.salonId = salonId;
    }
  }, [salonId]);

  const onPrintPdf = async () => {
    setIsLoading(true);
    if (createCustomerCheckinBulkMutation.data?.data) {
      for (const appointment of createCustomerCheckinBulkMutation.data.data) {
        const element = document.getElementById(`appointment_${appointment.id}`);
        if (!element) return false;
        element.style.marginLeft = '10px';
        element.style.marginRight = '10px';
        const componentWidth = element.offsetWidth + 20;
        const componentHeight = element.clientHeight;

        const orientation = componentWidth >= componentHeight ? 'l' : 'p';

        // const convertComponentToPng = await toPng(techDailySummaryRef.current);
        const scale = 153 / componentWidth;

        const pdf = new jsPDF({
          orientation,
          unit: 'px',
          format: [153, 153 * 3.7],
        });

        // pdf.internal.pageSize.width = 153;

        // pdf.internal.pageSize.height = componentHeight * scale;

        await pdf.html(element, { html2canvas: { scale: scale }, x: 0, y: 0, autoPaging: 'text' });

        const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });

        let newUploadFile = new FormData();
        newUploadFile.append('file', blob);

        printJobMutation.mutate(newUploadFile);
      }
    }
  };

  const onPrintTicket = async () => {
    setTimeout(async () => {
      try {
        if (createCustomerCheckinBulkMutation.data?.data) {
          for (const appointment of createCustomerCheckinBulkMutation.data.data) {
            const element = document.getElementById(`appointment_${appointment.id}`);

            if (element) {
              const convertComponentToPng = await toPng(element);

              cloverDevice.performPrint({
                base64Image: convertComponentToPng,
              });

              setTimeout(() => {
                cloverDevice.performPrint({
                  base64Image: convertComponentToPng,
                });
              }, 300);
            }
          }
        }
      } catch (error) {
        throw error;
      }
    }, 900);
  };

  const onFinish = (customerParams?: Customer) => {
    dataCustomerRef.current = customerParams;

    const appointments: CheckInAppointment[] = bookings.map((booking) => {
      const services = booking.isAppointment ? booking.appointmentServices : [booking.service];

      const estimate = services?.reduce((total, service) => total + Number(service?.time || 0), 0) || 15;
      const totalMoney = services?.reduce((total, service) => total + Number(service?.price || 0), 0) || 0;

      return {
        id: booking.isAppointment ? booking.id : undefined,
        technicianId: booking.technician?.id,
        useAnyTechnician: booking.isAnyTechnician,
        serviceIds: services?.map((service) => Number(service?.id))?.filter((service) => service) || [],
        estimate,
        totalMoney,
        checkInTime: booking?.availableTime
          ? toDaysByTimeZoneServer(booking?.availableTime, 'YYYY-MM-DD HH:mm')
          : dayjs().toISOString(),
      };
    });

    const customer = {
      customerName: customerParams ? customerParams?.name : dataCustomer?.name ?? '',
      customerPhone:
        customerParams?.phoneNumber?.replace(/[-\s]/g, '') ?? dataPhoneCheckin?.replace(/[-\s]/g, '') ?? '',
      customerEmail: customerParams ? customerParams?.emailAddress : dataCustomer?.emailAddress ?? '',
      birthDay: customerParams
        ? formatDateTimeUTC(customerParams?.birthDay)
        : formatDateTimeUTC(dataCustomer?.birthDay),
      receivePromotionViaEmail: receivePromotionViaEmail,
      receiveMessagesByPhone: receiveMessagesByPhone,
    };

    createCustomerCheckinBulkMutation.mutate({
      appointments: !!appointments?.length
        ? appointments
        : [
            {
              checkInTime: dayjs
                .tz(dayjs().format(DATE_FORMAT_FULL_DATE), DATE_FORMAT_FULL_DATE, timeZoneSalon)
                .toISOString(),
              estimate: 15,
              serviceIds: [],
              technicianId: undefined,
              totalMoney: 0,
              useAnyTechnician: true,
            } as CheckInAppointment,
          ],
      customer: customer,
    });
  };

  const onFinishTerms = () => {
    setIsTerms(true);
  };

  if (showCheckin && !status) {
    return (
      <CheckInQRCode
        setStatusShowCheckin={setStatusShowCheckin}
        bookingOnline={false}
        checkIn={true}
        setDataPhoneCheckin={setDataPhoneCheckin}
        setStep={setStep}
        dataPhoneCheckin={dataPhoneCheckin}
      />
    );
  } else {
    return settingLoading ? (
      <div className="min-height-600 d-flex justify-content-center align-items-center">
        <Spin />
      </div>
    ) : setting?.data.settingCheckin?.askForConsent && !isTerms ? (
      <section className="salon__information">
        <FormWrap
          name=""
          form={formTerms}
          layout="vertical"
          className="salon__information-form-terms"
          onFinish={onFinishTerms}
        >
          <div className="salon__online-appointment-container salon__online-appointment-terms-container w-100">
            <div className="d-flex justify-content-center align-items-center position-relative w-100">
              <div className="salon__information-back d-lg-flex d-none" onClick={onBack}>
                <SvgArrowBackIcon wrapperClassName="salon__information-back-icon" />
                <span className="salon__information-back-title">
                  {intl.formatMessage({ id: 'bookingOnline.back' })}
                </span>
              </div>
            </div>
            <div className="salon__information-main m-t-38">
              <p className="salon__online-appointment-title">{setting?.data.settingCheckin?.consentTitle}</p>
              <div className="salon__online-appointment-container-terms">
                <p className="salon__online-appointment-container-terms-content">
                  {setting?.data.settingCheckin?.consentContent}
                </p>
                {setting?.data.settingCheckin?.requireCustomers ? (
                  <FormCheckbox
                    name={'terms'}
                    content={setting?.data.settingCheckin?.consentPrompt ?? ''}
                    checkboxProps={{
                      className: 'salon__information-checkbox-terms',
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            <ButtonStyled
              content={
                <span className="font-size-24 line-height-40">
                  {intl.formatMessage({
                    id: 'common.agree',
                  })}
                </span>
              }
              isLarge={true}
              buttonProps={{
                className: 'salon__information-agree-terms',
                htmlType: 'submit',
                disabled: setting?.data.settingCheckin?.requireCustomers ? !termsChecked : false,
              }}
              isPrimary={true}
            />
          </div>
        </FormWrap>
      </section>
    ) : !status && !settingLoading ? (
      <section className="salon__information">
        <div className="salon__online-appointment-container">
          <div className="d-flex justify-content-center align-items-center position-relative">
            {/* {setting?.data.settingCheckin?.askForConsent && ( */}
            <div className="salon__information-back" onClick={onBack}>
              <SvgArrowBackIcon />
              <span className="salon__information-back-title">{intl.formatMessage({ id: 'bookingOnline.back' })}</span>
            </div>
            {/* )} */}

            {step !== 0 && (
              <div className="salon__information-back" onClick={onBack}>
                <SvgArrowBackIcon />
                <span className="salon__information-back-title">
                  {intl.formatMessage({ id: 'bookingOnline.back' })}
                </span>
              </div>
            )}
            <div className="salon__information-step ">
              <Steps current={step} responsive={false} labelPlacement="vertical" items={stepCheckIn} />
            </div>
          </div>
        </div>

        <div className="salon__information-main m-t-34">
          <div className="salon__online-appointment-container">
            {step === 0 && (
              <StepOne
                dataCustomer={dataCustomer}
                dataDetail={dataDetail}
                settingCheckin={setting?.data.settingCheckin}
                setDataDetail={setDataDetail}
                setStep={setStep}
                dataPhoneCheckin={dataPhoneCheckin}
                setDataCustomer={setDataCustomer}
                setCheckedTechnician={setCheckedTechnician}
                setSelectedTechnician={setSelectedTechnician}
                setListSelectedService={setListSelectedService}
                setReceivePromotionViaEmail={setReceivePromotionViaEmail}
                setReceiveMessagesByPhone={setReceiveMessagesByPhone}
                showKeyboard={windowWidth < 1366 ? false : !id}
                isShowNextBtn={windowWidth < 1366}
                setListMerchandise={setListMerchandise}
                setAppointmentId={setAppointmentId}
                onFinish={onFinish}
              />
            )}
            {step === 1 && (
              <StepTwo
                settingCheckin={setting?.data?.settingCheckin}
                services={dataServices?.data.content || []}
                technicians={dataTechnicians?.data || []}
                isLoadingService={isLoadingService}
                bookings={bookings}
                setBookings={setBookings}
                setStep={setStep}
              />
            )}
            {step === 2 && setting?.data?.settingCheckin?.asksOptionReceivingTextMessage && (
              <StepThree
                receivePromotionViaEmail={receivePromotionViaEmail}
                receiveMessagesByPhone={receiveMessagesByPhone}
                setReceivePromotionViaEmail={setReceivePromotionViaEmail}
                setReceiveMessagesByPhone={setReceiveMessagesByPhone}
                isLoading={createCustomerCheckinBulkMutation.isLoading || isLoading}
                onFinish={onFinish}
              />
            )}
          </div>
        </div>

        {createCustomerCheckinBulkMutation.isSuccess &&
          createCustomerCheckinBulkMutation.data.data.map((appointment, index) =>
            createPortal(
              <div
                className={`width-500 d-flex justify-content-center align-items-center position-absolute top-0 ${
                  500 - index > 0 ? `left-${500 - index}-n` : `right-${index - 500}-n`
                }`}
              >
                <CustomerCheckin
                  id={`appointment_${appointment.id}`}
                  wifiName={setting?.data?.settingCheckin?.freeWifi}
                  wifiPassword={setting?.data?.settingCheckin?.freeWifiPassword}
                  isHideCustomerPhoneNumber={!!setting?.data?.settingBusiness?.hideCustomerIncheckinAndCheckOut}
                  orderNumber={appointment.orderNumberCheckinToday || 0}
                  salonName={dataSalon?.data.name || ''}
                  salonAddress={dataSalon?.data.address}
                  salonHotline={setting?.data?.settingBusiness?.phoneNumber}
                  customerName={dataCustomerRef.current?.name || dataCustomer?.name || ''}
                  customerPhoneNumber={dataCustomerRef.current?.phoneNumber || dataCustomer?.phoneNumber || ''}
                  appointment={appointment}
                />
              </div>,
              document.body
            )
          )}

        {pairingCode && (
          <StyledPopup
            isOpen={!!pairingCode}
            content={
              <div>
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.pleaseEnterPairingCode' })}
                &nbsp;
                <span className="font-weight-700 text-transform-lowercase">{pairingCode}</span>
                &nbsp;
                {intl.formatMessage({ id: 'checkout.terminalDeviceSetting.onCloverDevice' })}?
              </div>
            }
            isHiddenCancel
            onCancel={() => dispatch(setPairingCode(undefined))}
            onOk={() => dispatch(setPairingCode(undefined))}
            modalProps={{
              zIndex: 100000,
            }}
          />
        )}
      </section>
    ) : (
      <ScreenSaver
        status={status}
        setStatus={setStatus}
        screenSaver={setting?.data.settingCheckin?.screenSaver ?? []}
        salonHome={true}
      />
    );
  }
};

export default Information;
