import { Divider, Image } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { StyledModal } from '../../../../../components/StyledModal';
import TableWrap from '../../../../../components/TableWrap';
import { ButtonStyled } from '../../../../../components/buttons/ButtonStyled';
import { formatNumberThousandWithDecimal } from '../../../../../utils';
import { AppointmentResource } from '../../models';
import './style.scss';

interface TechnicianDetailDayProps {
  open: boolean;
  onCancel: () => void;
  technician?: AppointmentResource;
  onOpenBlockTime: (technician: AppointmentResource) => void;
}

const TechnicianDetailDay: FC<TechnicianDetailDayProps> = (props) => {
  const { open, onCancel, technician, onOpenBlockTime } = props;

  const intl = useIntl();

  const columns: ColumnType<any>[] = [
    {
      align: 'center',
      rowScope: 'row',
      dataIndex: 'key',
      key: 0,
      render: (value) => <span className="font-size-12 font-weight-500">{value}</span>,
    },
    {
      align: 'center',
      dataIndex: 'counted',
      key: 1,
      title: (
        <span className="font-size-12 font-weight-500">
          {intl.formatMessage({ id: 'appointment.modal.technician.detail.day.counted' })}
        </span>
      ),
      render: (value, record) => {
        if (record.key === intl.formatMessage({ id: 'appointment.modal.technician.detail.day.money' }))
          return (
            <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">
              ${formatNumberThousandWithDecimal(value)}
            </span>
          );

        return <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">{value}</span>;
      },
    },
    {
      align: 'center',
      dataIndex: 'notCounted',
      key: 2,
      title: (
        <span className="font-size-12 font-weight-500">
          {intl.formatMessage({ id: 'appointment.modal.technician.detail.day.notCounted' })}
        </span>
      ),
      render: (value, record, index) => {
        if (record.key === intl.formatMessage({ id: 'appointment.modal.technician.detail.day.money' }))
          return (
            <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">
              ${formatNumberThousandWithDecimal(value)}
            </span>
          );

        return <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">{value}</span>;
      },
    },
    {
      align: 'center',
      dataIndex: 'filler',
      key: 3,
      title: (
        <span className="font-size-12 font-weight-500">
          {intl.formatMessage({ id: 'appointment.modal.technician.detail.day.filler' })}
        </span>
      ),
      render: (value, record) => {
        if (record.key === intl.formatMessage({ id: 'appointment.modal.technician.detail.day.money' }))
          return (
            <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">
              ${formatNumberThousandWithDecimal(value)}
            </span>
          );

        return <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">{value}</span>;
      },
    },
    {
      align: 'center',
      dataIndex: 'actual',
      key: 4,
      title: (
        <span className="font-size-12 font-weight-500">
          {intl.formatMessage({ id: 'appointment.modal.technician.detail.day.actual' })}
        </span>
      ),
      render: (value, record) => {
        if (record.key === intl.formatMessage({ id: 'appointment.modal.technician.detail.day.money' }))
          return (
            <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">
              ${formatNumberThousandWithDecimal(value)}
            </span>
          );

        return <span className="font-size-12 font-weight-500 salon__appointment-detail-column-text">{value}</span>;
      },
    },
  ];

  const data = [
    {
      key: intl.formatMessage({ id: 'appointment.modal.technician.detail.day.tickets' }),
      counted: 1,
      notCounted: 1,
      filler: 1,
      actual: 1,
    },
    {
      key: intl.formatMessage({ id: 'appointment.modal.technician.detail.day.turns' }),
      counted: 1,
      notCounted: 1,
      filler: 1,
      actual: 1,
    },
    {
      key: intl.formatMessage({ id: 'appointment.modal.technician.detail.day.money' }),
      counted: 1,
      notCounted: 1,
      filler: 1,
      actual: 1,
    },
  ];

  return (
    <StyledModal
      isOpen={open}
      onCancel={onCancel}
      modalProps={{
        title: (
          <div className="salon__appointment-detail-heading m-l-r-24-n">
            <Image alt={technician?.resourceTitle} src={technician?.avatar} preview={false} width={32} height={32} />
            <span className="font-size-16 font-weight-600 truncate">{technician?.resourceTitle}</span>
          </div>
        ),
        centered: true,
        footer: null,
        rootClassName: 'salon__appointment-detail',
        className: 'salon__appointment-modal-create-turn',
      }}
    >
      <div className="salon__appointment-detail-wrapper m-l-r-24-n">
        <p className="font-size-12 font-weight-600 salon__appointment-detail-title">
          {intl.formatMessage({ id: 'appointment.modal.technician.detail.day.title' })}
        </p>
        <TableWrap isHidePagination setSize={() => {}} columns={columns} data={data} rowKey="key" />
        <Divider className="m-b-13 m-t-24 m-l-r-24-n w-auto" />
        <div className="salon__appointment-detail-button-wrapper">
          <ButtonStyled
            isPrimary
            content={
              <span className="font-size-16 font-weight-700">
                {intl.formatMessage({ id: 'appointment.button.create.blocktime' })}
              </span>
            }
            buttonProps={{
              className: 'salon__appointment-detail-button',
              onClick: () => onOpenBlockTime(technician as AppointmentResource),
            }}
          />
        </div>
      </div>
    </StyledModal>
  );
};

export default TechnicianDetailDay;
